import React from "react"
import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import getAppUrl from "@src/utils/getAppUrl"
import Section from "@src/components/Section"
import FeatureGrid from "@src/components/FeatureGrid"
import Text from "@src/components/Text"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import track from "@src/utils/track"

import { ReactComponent as GoogleSheetsIcon } from "@src/icons/v2/google-sheets-icon.svg"
import { ReactComponent as AsanaIcon } from "@src/icons/v2/asana-icon.svg"
import { ReactComponent as TwitterIntegrations } from "@src/icons/v2/twitter-integrations.svg"
import { ReactComponent as GithubIntegrations } from "@src/icons/v2/github-integrations.svg"
import { ReactComponent as YoutubeIntegration } from "@src/icons/v2/youtube-integrations.svg"
import { ReactComponent as StripeIntegration } from "@src/icons/v2/stripe-integrations.svg"
import { ReactComponent as SlackIntegration } from "@src/icons/v2/slack-integrations.svg"
import SlackBlog from "@src/icons/v2/slack-blog.png"

import * as styles from "./variation.module.scss"

const SlackIntegrations = ({ location }) => {
  const mainAppUrl = getAppUrl(location, "/dashboard/marketplace/integrations")

  return (
    <Layout
      title="Save time and automate repetitive tasks with Slack integrations"
      description="Turn slow, repetitive, manual processes, like onboarding or gathering feedback, into rapid and automated apps and workflows."
      url="https://canonic.dev/features/slack-integrations"
      location={location}
    >
      <SplashWithIllustration
        isWhite
        illustrationComponent={<SlackIntegration />}
        className={styles.SocialIntegrations}
        heading="Slack Integration"
        title="Save time and automate repetitive tasks with Slack integrations"
        subtitle="Turn slow, repetitive, manual processes, like onboarding or gathering feedback, into rapid and automated apps and workflows."
        buttons={[
          {
            title: "Clone & get started →",
            isPrimary: true,
            href: `${mainAppUrl}`,
            onClick: () => track("Start for free", location.pathname),
          },
        ]}
      />
      <Section blueGradient className={styles.SocialIntegrationsActions}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Features
        </Text.Heading>
        <Text.H2 white>Few of the Triggers and Actions</Text.H2>

        <FeatureGrid cards className={styles.SocialIntegrationsActionsCards}>
          {[
            {
              title: "Manage Chats",
              description: `Perform actions such as delete, send, schedule, update and delete scheduled message.`,
            },

            {
              title: "Manage Conversation",
              description: `Perform actions such as close, create, archive. Join, kick, leave, List and many more.`,
            },
            {
              title: "Manage Files",
              description: `Perform actions such as add, delete, share, update and list a file.`,
            },
            {
              title: "View Teams",
              description: `Get a teams's info,billable info, access logs, integrations logs, preference list and profiles.`,
            },
          ]}
        </FeatureGrid>
      </Section>

      <Section white lassName={styles.SocialIntegrationsSamples}>
        <div className={styles.SocialIntegrationsSamplesWrapper}>
          <div className={styles.SocialIntegrationsSamplesWrapperFirst}>
            <Text.Heading>Sample Project</Text.Heading>
            <Text.H2 lightBlueGradient>
              Clone the project built with GitHub integration or read the guide
              on how to build it
            </Text.H2>
            <FeatureGrid cards white>
              {[
                {
                  title: "Create an awesome slack approval workflow",
                  description:
                    "Trigger interactive workflows on slack, and get the response back with a webhook endpoint to further add a pin in pinterest.",
                  link: {
                    title: "Clone Project",
                    url: "https://app.canonic.dev/projects/61be5cc3b6586d0026b16023/graph",
                  },
                },
              ]}
            </FeatureGrid>
          </div>
          <div className={styles.SocialIntegrationsSamplesWrapperSecond}>
            <div className={styles.blogCards}>
              <div>
                <figure className={styles.blogCardsFigure}>
                  <img src={SlackBlog} />
                </figure>
                <Text.H3>
                  How to create an awesome slack approval workflow
                </Text.H3>

                <Text.P>
                  Trigger interactive workflows on slack, and get the response
                  back with a webhook endpoint to further add a pin in pinterest
                </Text.P>
                <a
                  href={
                    "https://dev.to/canonic/how-to-create-an-awesome-slack-approval-workflow-1gdg"
                  }
                >
                  <button>Step by Step Guide </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section lightBlue className={styles.SocialIntegrationsApp}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>Pick an App to pair with</Text.H2>
        <FeatureGrid cards className={styles.SocialIntegrationsAppCards}>
          {[
            {
              icon: GoogleSheetsIcon,
              title: "Google Sheets",
              description: `Spreadsheets  |  Google `,
            },
            {
              icon: AsanaIcon,
              title: "Asana",
              description: `Project Management`,
            },
            {
              icon: GithubIntegrations,
              title: "GitHub",
              description: `Version Management`,
            },
            {
              icon: TwitterIntegrations,
              title: "Twitter",
              description: `Social Media`,
            },
            {
              icon: StripeIntegration,
              title: "Stripe",
              description: `Payment Gateway`,
            },
            {
              icon: YoutubeIntegration,
              title: "Youtube",
              description: `Entertainment`,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Section black className={styles.SocialIntegrationsBenefits}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>
          Here’s why you should integrate using Canonic
        </Text.H2>
        <FeatureGrid className={styles.SocialIntegrationsBenefitsCards}>
          {[
            {
              //   icon: PlaceholderIcon,
              title: "Plenty of Inegrations",
              description: `A good deal of full featured set of integrations.`,
            },
            {
              //   icon: PlaceholderIcon,
              title: "Chanining Multiple Integration",
              description: `Multiple integrations can be chained to one another.`,
            },
            {
              //   icon: PlaceholderIcon,
              title: "Test your Data",
              description: `Test the data you are receiving on the GraphQL Playground.`,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Callout location={location} title="slackIntegrations" />
      <CommunityCallout />
    </Layout>
  )
}

export default SlackIntegrations
